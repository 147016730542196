@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
  scroll-behavior: smooth;
}

.glow-on-hover {
  transition: all 0.3s ease;
}

.box-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
}

.glow-on-hover:hover {
  box-shadow: 0 0 10px #019DEA;
}

@keyframes move-dot1 {

  0%,
  100% {
    left: 0;
  }

  50% {
    left: calc(100% - 1px);
  }
}

@keyframes move-dot2 {

  0%,
  100% {
    left: calc(100% - 1px);
  }

  50% {
    left: 0;
  }
}

.animate-dot1,
.animate-dot2 {
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.animate-dot1 {
  animation-name: move-dot1;
}

.animate-dot2 {
  animation-name: move-dot2;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-3px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake-on-hover:hover {
  animation: shake 0.5s ease-in-out;
}

.shake-on-parent-visit {
  animation: shake 0.5s ease-in-out;
}

.map {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}