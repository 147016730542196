:root {
    --color1: #01AAE8;
    --color2: #01AAE8;
    --color3: #01AAE8;
    --color4: #01AAE8;
    --size: 2.5em;
    --thickness: 0.5em;
}

:root {
    --lat: calc((var(--size) - var(--thickness)) / 2);
    --offset: calc(var(--lat) - var(--thickness));
}

.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    /* Semi-transparent backdrop */
    z-index: 9999;
    /* Ensure it covers other elements */
}

.loader {
    position: relative;
    width: var(--size);
    height: var(--size);
    transform: rotate(165deg);
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: var(--thickness);
    height: var(--thickness);
    border-radius: calc(var(--thickness) / 2);
    transform: translate(-50%, -50%);
}

.loader:before {
    animation: before 2s infinite;
}

.loader:after {
    animation: after 2s infinite;
}

@keyframes before {
    0% {
        width: var(--thickness);
        box-shadow:
            var(--lat) calc(-1 * var(--offset)) var(--color1),
            calc(-1 * var(--lat)) var(--offset) var(--color3);
    }

    35% {
        width: var(--size);
        box-shadow:
            0 calc(-1 * var(--offset)) var(--color1),
            0 var(--offset) var(--color3);
    }

    70% {
        width: var(--thickness);
        box-shadow:
            calc(-1 * var(--lat)) calc(-1 * var(--offset)) var(--color1),
            var(--lat) var(--offset) var(--color3);
    }

    100% {
        box-shadow:
            var(--lat) calc(-1 * var(--offset)) var(--color1),
            calc(-1 * var(--lat)) var(--offset) var(--color3);
    }
}

@keyframes after {
    0% {
        height: var(--thickness);
        box-shadow:
            var(--offset) var(--lat) var(--color2),
            calc(-1 * var(--offset)) calc(-1 * var(--lat)) var(--color4);
    }

    35% {
        height: var(--size);
        box-shadow:
            var(--offset) 0 var(--color2),
            calc(-1 * var(--offset)) 0 var(--color4);
    }

    70% {
        height: var(--thickness);
        box-shadow:
            var(--offset) calc(-1 * var(--lat)) var(--color2),
            calc(-1 * var(--offset)) var(--lat) var(--color4);
    }

    100% {
        box-shadow:
            var(--offset) var(--lat) var(--color2),
            calc(-1 * var(--offset)) calc(-1 * var(--lat)) var(--color4);
    }
}

html,
body {
    height: 100%;
    margin: 0;
}